var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form", attrs: { id: _vm.ref } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xl: "5", lg: "5", md: "12", sm: "12" } },
            [
              _c("google-auto-complete", {
                attrs: {
                  "distance-valid": _vm.distanceValid,
                  label: _vm.fromLocationLabel,
                  placeholder: _vm.FormMSG(1, "From ..."),
                  "inline-validator": _vm.inlineValidator,
                  "is-submitted": _vm.isSubmitted,
                  "error-message": _vm.fromLocationErrorMessage,
                  disabled: _vm.disabled,
                },
                on: {
                  placechanged: _vm.handleAutoCompleteFromChange,
                  "placechanged-manually":
                    _vm.handleAutoCompleteFromManuallyChanged,
                  "google-location:selector:invalid":
                    _vm.isInvalidateFromFields,
                },
                model: {
                  value: _vm.payload[_vm.fieldFrom],
                  callback: function ($$v) {
                    _vm.$set(_vm.payload, _vm.fieldFrom, $$v)
                  },
                  expression: "payload[fieldFrom]",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            {
              class:
                _vm.$screen.width > 992
                  ? "d-flex flex-column justify-content-start align-items-center"
                  : "d-flex flex-column justify-content-start align-items-start mb-3",
              attrs: { xl: "2", lg: "2", md: "12", sm: "12" },
            },
            [
              _c("label", { class: _vm.$screen.width > 992 ? "" : "-mb-3" }, [
                _vm._v(_vm._s(_vm.FormMSG(4, "Round Trip"))),
              ]),
              _c("b-form-checkbox", {
                attrs: { value: true, "unchecked-value": false },
                model: {
                  value: _vm.roundTrip,
                  callback: function ($$v) {
                    _vm.roundTrip = $$v
                  },
                  expression: "roundTrip",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "5", lg: "5", md: "12", sm: "12" } },
            [
              _c("google-auto-complete", {
                attrs: {
                  "distance-valid": _vm.distanceValid,
                  label: _vm.toLocationLabel,
                  placeholder: _vm.FormMSG(2, "To ..."),
                  "inline-validator": _vm.inlineValidator,
                  "is-submitted": _vm.isSubmitted,
                  "error-message": _vm.toLocationErrorMessage,
                  disabled: _vm.disabled,
                },
                on: {
                  placechanged: _vm.handleAutoCompleteDestinationChange,
                  "placechanged-manually":
                    _vm.handleAutoCompleteDestinationManuallyChanged,
                  "google-location:selector:invalid": _vm.isInvalidateToFields,
                },
                model: {
                  value: _vm.payload[_vm.fieldTo],
                  callback: function ($$v) {
                    _vm.$set(_vm.payload, _vm.fieldTo, $$v)
                  },
                  expression: "payload[fieldTo]",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showTravelMode
        ? _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.FormMSG(3, "Travel distance"),
                "label-for": "slection__mode_travel-distance",
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-select", {
                        attrs: {
                          id: "slection__mode_travel-distance",
                          options: _vm.travelModeOptions,
                          disabled: _vm.disabled,
                        },
                        on: { input: _vm.handleTravelModeSelected },
                        model: {
                          value: _vm.selectedTravelMode,
                          callback: function ($$v) {
                            _vm.selectedTravelMode = $$v
                          },
                          expression: "selectedTravelMode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "7" } },
                    [
                      _c("b-form-input", {
                        attrs: { placeholder: "0", disabled: "", required: "" },
                        model: {
                          value: _vm.duration,
                          callback: function ($$v) {
                            _vm.duration = $$v
                          },
                          expression: "duration",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
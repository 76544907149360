<template>
	<div :id="ref" class="form">
		<b-row>
			<b-col xl="5" lg="5" md="12" sm="12">
				<google-auto-complete
					:distance-valid="distanceValid"
					v-model="payload[fieldFrom]"
					:label="fromLocationLabel"
					:placeholder="FormMSG(1, 'From ...')"
					:inline-validator="inlineValidator"
					:is-submitted="isSubmitted"
					:error-message="fromLocationErrorMessage"
					:disabled="disabled"
					@placechanged="handleAutoCompleteFromChange"
					@placechanged-manually="handleAutoCompleteFromManuallyChanged"
					@google-location:selector:invalid="isInvalidateFromFields"
				/>
			</b-col>
			<b-col
				xl="2"
				lg="2"
				md="12"
				sm="12"
				:class="
					$screen.width > 992
						? 'd-flex flex-column justify-content-start align-items-center'
						: 'd-flex flex-column justify-content-start align-items-start mb-3'
				"
			>
				<label :class="$screen.width > 992 ? '' : '-mb-3'">{{ FormMSG(4, 'Round Trip') }}</label>
				<b-form-checkbox v-model="roundTrip" :value="true" :unchecked-value="false" />
			</b-col>
			<b-col xl="5" lg="5" md="12" sm="12">
				<google-auto-complete
					:distance-valid="distanceValid"
					v-model="payload[fieldTo]"
					:label="toLocationLabel"
					:placeholder="FormMSG(2, 'To ...')"
					:inline-validator="inlineValidator"
					:is-submitted="isSubmitted"
					:error-message="toLocationErrorMessage"
					:disabled="disabled"
					@placechanged="handleAutoCompleteDestinationChange"
					@placechanged-manually="handleAutoCompleteDestinationManuallyChanged"
					@google-location:selector:invalid="isInvalidateToFields"
				/>
			</b-col>
		</b-row>
		<b-form-group v-if="showTravelMode" :label="FormMSG(3, 'Travel distance')" label-for="slection__mode_travel-distance">
			<b-row>
				<b-col cols="5">
					<v-select
						v-model="selectedTravelMode"
						id="slection__mode_travel-distance"
						:options="travelModeOptions"
						:disabled="disabled"
						@input="handleTravelModeSelected"
					/>
				</b-col>
				<b-col cols="7">
					<b-form-input v-model="duration" placeholder="0" disabled required />
				</b-col>
			</b-row>
		</b-form-group>
	</div>
</template>

<script>
import { isNil, makeID, objReducer } from '~utils';
import { fetchDistance } from '@/shared/google/helpers';
import languageMessages from '@/mixins/languageMessages';

import GoogleAutoComplete from '@/components/GoogleAutoComplete';
import { store } from '@/store';

export default {
	name: 'GoogleDistanceComponent',
	components: { GoogleAutoComplete },
	mixins: [languageMessages],
	props: {
		distanceValid: {
			type: Boolean,
			required: false,
			default: false
		},
		editData: {
			type: Object,
			required: false,
			default: null
		},
		toLocationLabel: {
			type: String,
			required: false,
			default: 'To location'
		},
		fromLocationLabel: {
			type: String,
			required: false,
			default: 'From location'
		},
		showTravelMode: {
			type: Boolean,
			required: false,
			default: false
		},
		isSubmitted: {
			type: Boolean,
			required: false,
			default: false
		},
		toLocationErrorMessage: {
			type: String,
			required: false,
			default: '"To location" is required'
		},
		fromLocationErrorMessage: {
			type: String,
			required: false,
			default: '"From location" is required'
		},
		inlineValidator: {
			type: Boolean,
			required: false,
			default: false
		},
		fieldFrom: {
			type: String,
			required: false,
			default: 'fromLocation'
		},
		fieldTo: {
			type: String,
			required: false,
			default: 'toLocation'
		},
		fieldTotalDistance: {
			type: String,
			required: false,
			default: 'km'
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			payload: Object.assign(
				{},
				{
					[this.fieldFrom]: '',
					[this.fieldTo]: '',
					[this.fieldTotalDistance]: 0
				}
			),
			duration: 0,
			selectedTravelMode: {},
			inc: 0,
			roundTrip: false
		};
	},
	computed: {
		/**
		 * @param {String}
		 */
		ref() {
			return `_distance_location_${makeID(5)}`;
		},
		/**
		 * @return {Array}
		 */
		travelModeOptions() {
			return [
				{
					label: this.FormMSG(232301, 'Drive'),
					code: 'DRIVING'
				},
				{
					label: this.FormMSG(232302, 'Walking'),
					code: 'WALKING'
				},
				{
					label: this.FormMSG(232303, 'Transit'),
					code: 'TRANSIT'
				}
			];
		}
	},
	watch: {
		payload: {
			/**
			 * @param {Object}
			 */
			handler(options) {
				this.setCalculation(options);
			},
			deep: true
		},
		selectedTravelMode: {
			/**
			 * @param {Object}
			 */
			handler() {
				this.setCalculation(this.payload);
			},
			deep: true
		},
		editData: {
			handler(value) {
				if (!isNil(value)) {
					this.initEditMode();
				}
			},
			deep: true,
			immediate: false
		},
		roundTrip: {
			handler() {
				this.calculDistance(this.roundTrip);
			}
		}
	},
	created() {
		this.selectedTravelMode = Object.assign({}, this.travelModeOptions[0]);
	},
	methods: {
		initEditMode() {
			const _E = objReducer([this.fieldFrom, this.fieldTo], this.editData);
			this.roundTrip = this.editData.roundTrip;
			this.payload = Object.assign(this.payload, _E);
		},
		/**
		 * @param {Object}
		 */
		setCalculation({ [this.fieldFrom]: f, [this.fieldTo]: t }) {
			const nil = isNil(f) || isNil(t);
			const empty = f === '' || t === '';
			if (!nil && !empty) this.calculDistance();
		},
		async calculDistance() {
			this.$emit('google-distance:loading', true);
			//console.log({ calculDistance: this.payload })
			const res = await fetchDistance({
				from: this.payload[this.fieldFrom],
				to: this.payload[this.fieldTo],
				travelMode: this.selectedTravelMode.code
			});
			// console.log({ res })
			const { distance, duration } = res.rows[0].elements[0];
			let distanceCalculatedUnit = 0;

			if (!isNil(distance)) {
				if (store.state.myProfile.distanceUnit.toLowerCase() === 'km') {
					if (this.roundTrip) {
						distanceCalculatedUnit = distance.value / 1000;
						distanceCalculatedUnit *= 2;
					} else {
						distanceCalculatedUnit = distance.value / 1000;
					}
				} else if (store.state.myProfile.distanceUnit.toLowerCase() === 'miles') {
					if (this.roundTrip) {
						distanceCalculatedUnit = (distance.value / (1000 * 1.61)).toFixed(3);
						distanceCalculatedUnit *= 2;
					} else {
						distanceCalculatedUnit = (distance.value / (1000 * 1.61)).toFixed(3);
					}
				}

				if (this.showTravelMode) this.duration = duration.text;
				this.$emit('change', {
					[this.fieldTo]: res.destinationAddresses[0],
					[this.fieldFrom]: res.originAddresses[0],
					[this.fieldTotalDistance]: distanceCalculatedUnit,
					duration: this.duration, // meters in km
					roundTrip: this.roundTrip
				});
			} else {
				this.$emit('change', {
					[this.fieldTo]: this.payload[this.fieldTo],
					[this.fieldFrom]: this.payload[this.fieldFrom],
					[this.fieldTotalDistance]: 0,
					duration: this.duration, // meters in km
					roundTrip: this.roundTrip
				});
			}
			this.$emit('google-distance:loading', false);
		},
		/**
		 * @param {Object} address
		 */
		handleAutoCompleteFromChange(address) {
			if (isNil(address)) return;
			this.payload[this.fieldFrom] = this.addressFormatted(address);
		},
		/**
		 * @param {Object} address
		 */
		handleAutoCompleteDestinationChange(address) {
			if (isNil(address)) return;
			this.payload[this.fieldTo] = this.addressFormatted(address);
		},
		/**
		 * @param {Object} travelMode
		 */
		handleTravelModeSelected(travelMode) {
			if (isNil(travelMode)) return;
			this.selectedTravelMode = travelMode;
		},
		isInvalidateFromFields(payload) {
			if (this.distanceValid) {
				this.$emit('google-distance:selector-from:invalid', payload);
			}
		},
		isInvalidateToFields(payload) {
			if (this.distanceValid) {
				this.$emit('google-distance:selector-to:invalid', payload);
			}
		},
		handleAutoCompleteFromManuallyChanged(payload) {
			this.payload[this.fieldFrom] = payload;
		},
		handleAutoCompleteDestinationManuallyChanged(payload) {
			this.payload[this.fieldTo] = payload;
		},
		addressFormatted(address) {
			if (isNil(address)) return '';

			return address.formatted_address;
		}
	}
};
</script>

<style scoped></style>
